// FilterComponent.js

import React from 'react';
import { Disclosure } from '@headlessui/react';
import { MinusIcon, PlusIcon } from '@heroicons/react/20/solid';
import PropTypes from 'prop-types';

const SidebarFilterComponent = ({ section, selectedCategory, onChange }) => (
    <Disclosure as="div" key={section.id} className="border-b border-gray-200 py-6">
        {({ open }) => (
        <>
            <h3 className="-my-3 flow-root">
            <Disclosure.Button className="flex w-full items-center justify-between bg-white py-3 text-sm text-gray-400 hover:text-gray-500">
                <span className="font-medium text-gray-900">{section.name}</span>
                <span className="ml-6 flex items-center">
                {open ? (
                    <MinusIcon className="h-5 w-5" aria-hidden="true" />
                ) : (
                    <PlusIcon className="h-5 w-5" aria-hidden="true" />
                )}
                </span>
            </Disclosure.Button>
            </h3>
            <Disclosure.Panel className="pt-6">
            <div className="space-y-4">
                {section.options.map((option, optionIdx) => (
                <div key={option.value} className="flex items-center">
                    <input
                    id={`filter-${section.id}-${optionIdx}`}
                    name={`${section.id}[]`}
                    value={option.value}
                    checked={selectedCategory === option.value}
                    type="radio"
                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                    onChange={() => onChange(option.value)}
                    />
                    <label
                    htmlFor={`filter-${section.id}-${optionIdx}`}
                    className="ml-3 text-sm text-gray-600 capitalize lowercase-camel"
                    style={{ textTransform: 'capitalize' }}

                    >
                {option.label.toLowerCase().replace(/\b\w/g, c => c.toUpperCase())}
                    </label>
                </div>
                ))}
            </div>
            </Disclosure.Panel>
        </>
        )}
    </Disclosure>
);

SidebarFilterComponent.propTypes = {
    section: PropTypes.object.isRequired,
    selectedCategory: PropTypes.string,
    handleCategoryChange: PropTypes.func.isRequired,
};

export default SidebarFilterComponent;
