import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Carousel } from 'react-bootstrap';
import BaseImageSlider from './BaseImageSlider';
import styles from './ProductDetails.css';
import { StarIcon } from '@heroicons/react/20/solid'
import { RadioGroup } from '@headlessui/react';
import { CurrencyDollarIcon, GlobeAmericasIcon } from '@heroicons/react/24/outline'
import ImageGallery from "react-image-gallery";
import {fetchProduct as fetchProductUsingApi} from './api';
import config from "./config";


function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const policies = [
  { name: 'International delivery', icon: GlobeAmericasIcon, description: 'Over 180 Countries' },
  { name: 'Loyalty rewards', icon: CurrencyDollarIcon, description: "Don't look at other tees" },
]


const productOld = {
  name: 'Basic Tee',
  price: '$35',
  rating: 3.9,
  reviewCount: 512,
  href: '#',
  breadcrumbs: [
    { id: 1, name: 'Women', href: '#' },
    { id: 2, name: 'Clothing', href: '#' },
  ],
  details: [
    'Only the best materials',
    'Ethically and locally made',
    'Pre-washed and pre-shrunk',
    'Machine wash cold with similar colors',
  ],
}

function ProductDetails() {
  const { productId } = useParams();

  const [product, setProduct] = useState(null);

  const [selectedBaseImageId, setSelectedBaseImageId] = useState(null);
 
  // Add two new state variables for colors and sizes
  const [colors, setColors] = useState([]);
  const [sizes, setSizes] = useState([]);

  const [selectedColor, setSelectedColor] = useState(null); // Don't initialize with product.colors[0]
  const [selectedSize, setSelectedSize] = useState(null); // Don't initialize with product.sizes[2]
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [galleryImages, setGalleryImages] = useState([]);
  const [variantImages, setVariantImages] = useState([]);

  const [test, setTest] = useState([]);

  /** Handlers */

  // Callback function to update the selected base image ID
  const handleBaseImageSelect = (baseImageId) => {
    if (baseImageId === null) return;
    setSelectedBaseImageId(baseImageId);
  };

  const handleAddToStore = () => {
    window.open('https://product-engine.kitkoo.com/generator/products', '_blank');
  };

  const getUniqueBaseImages = () => {
    const baseImages = [];
    const extractImageName = (url) => {
      if (url == null) { // This will check for both `null` and `undefined`
        return null;
      }

      const urlParts = url.split('/');
      return urlParts[urlParts.length - 1];
    };

    if (product.variants) {
      product.variants.forEach((variant) => {
        if (variant.base_images) {
          variant.base_images.forEach((baseImage) => {
            const baseImageName = extractImageName(baseImage.url);
            if (!baseImages.some((image) => extractImageName(image.url) === baseImageName)) {
              baseImages.push(baseImage);
            }
          });
        }
      });
    }
console.log('getUniqueBaseImages', baseImages);
    return baseImages;
  };

  const fetchProduct = async () => {
    const data = await fetchProductUsingApi(productId)
    setProduct(data);

    // Extract unique colors and sizes from the variants array
    const uniqueColorsValues = new Set();
    const uniqueSizesValues = new Set();

    const uniqueColors = data.variants.map(variant => variant.attributes?.color ?? null).filter(item => {
      if(!item) return false;
      if (uniqueColorsValues.has(item.value)) return false;

      uniqueColorsValues.add(item.value);
      return true;
    });
    const uniqueSizes = data.variants.map(variant => variant.attributes?.size ?? null).filter(item => {
      if(!item) return false;
      if (uniqueColorsValues.has(item.value)) return false;

      uniqueColorsValues.add(item.value);
      return true;
    });

    setColors(uniqueColors);
    setSizes(uniqueSizes);

    // Initialize selectedColor and selectedSize
    if (uniqueColors.length > 0) {
      // setSelectedColor(data.variants.find(variant => variant.color === uniqueColors[0]));
      setSelectedColor(uniqueColors[0]);
    }
    // if (uniqueSizes.length > 2) {
    if (uniqueSizes.length > 0) {
      const size = selectedVariant?.attributes?.size ?? uniqueSizes[0];
      // setSelectedSize(data.variants.find(variant => variant.size === uniqueSizes[2]));
      setSelectedSize(size);
    }

    // Convert the variant images into the format that react-image-gallery expects
    // setVariantImages(getVariantImages());

    console.log('product', data);
  }
 
  const getVariantForAttributes = function (color, size) {
    return product?.variants.find((variant) => {
      return (
        (!color || (variant.attributes?.color?.value && variant.attributes.color.value === color)) &&
        (!size || (variant.attributes?.size?.value && variant.attributes.size.value === size))
      );
    });
  }

  const getVariantImages = () => {
    const images = [];

    if (product && product.variants && product.variants.length > 0) {
      const uniqueColors = new Set();
      product.variants.forEach((variant) => {
        const color = variant.attributes?.color?.value ?? null;
        if (!uniqueColors.has(color) && variant.url_preview) {
          uniqueColors.add(color);
          images.push({ color: color, image: variant.url_preview });
        }
      });
    } else if (product && product.url_preview) {
      images.push({ color: null, image: product.url_preview });
    }
    console.log('getVariantImages', (product && product.variants && product.variants.length > 0), images);
    return images;
  };

  const getBaseImagesForVariant = (variant) => {
    const baseImages = [];

    if (variant.base_images) {
      variant.base_images.forEach((baseImage) => {
        baseImages.push(baseImage);
      });
    }
    console.log('getBaseImagesForVariant', variant.id, baseImages);
    return baseImages;
  };


  /** Effects */

  useEffect(() => {
    fetchProduct();
  }, [productId]);

  useEffect(() => {
    if (!product) return;

    const variantImages = getVariantImages();
    setVariantImages(variantImages);
    console.log('[product]: setVariantImages', variantImages);
    const galleryImages = variantImages.map((image) => ({
      original: image.image,
      thumbnail: image.image,
    }));
    setGalleryImages(galleryImages);
    console.log('[product]: galleryImages', galleryImages);

    const selectedVariant = product?.variants?.[0];
    setSelectedVariant(selectedVariant);
    console.log('[product]: selectedVariant', selectedVariant);
  }, [product]);

  useEffect(() => {
    console.log('VariantImages updated', variantImages);
  }, [variantImages]);

  useEffect(() => {
    if (!product) return;
    console.log('[selectedColor]: selectedColor', selectedColor);

    const selectedVariant = getVariantForAttributes(selectedColor?.value, selectedSize?.value);
    setSelectedVariant(selectedVariant);
    console.log('[selectedColor]: selectedVariant', selectedVariant, {selectedColor, selectedSize});

    const selectedVariantImage = variantImages.find((variantImage) => variantImage.color === selectedColor.value);
    console.log('[selectedColor]: selectedVariantImage', selectedVariantImage, {selectedColor, selectedSize});
    if (selectedVariantImage) {
        setSelectedImage(selectedVariantImage.image);
        setCurrentIndex(galleryImages.findIndex(img => img.original === selectedVariantImage.image));
    } else if (variantImages.length === 0) {
        setSelectedImage(product.image);
    } else if (variantImages.length > 0) {
        setSelectedImage(product.image || variantImages[0].image);
        setCurrentIndex(0);
    }

  }, [selectedColor]);


  if (!product) {
    return (
      <div className="bg-white">
        <div className="pb-16 pt-6 sm:pb-24">
          <div className="mx-auto mt-8 max-w-2xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
            <div>Loading...</div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white">
      <div className="pb-16 pt-6 sm:pb-24">
        <nav aria-label="Breadcrumb" className="hidden mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <ol role="list" className="flex items-center space-x-4">
            {productOld.breadcrumbs.map((breadcrumb) => (
              <li key={breadcrumb.id}>
                <div className="flex items-center">
                  <a href={breadcrumb.href} className="mr-4 text-sm font-medium text-gray-900">
                    {breadcrumb.name}
                  </a>
                  <svg viewBox="0 0 6 20" aria-hidden="true" className="h-5 w-auto text-gray-300">
                    <path d="M4.878 4.34H3.551L.27 16.532h1.327l3.281-12.19z" fill="currentColor" />
                  </svg>
                </div>
              </li>
            ))}
            <li className="text-sm">
              <a href={product.href} aria-current="page" className="font-medium text-gray-500 hover:text-gray-600">
                {product.name}
              </a>
            </li>
          </ol>
        </nav>
        <div className="mx-auto mt-8 max-w-2xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
          <div className="lg:grid lg:auto-rows-min lg:grid-cols-12 lg:gap-x-8">
            <div className="lg:col-span-5 lg:col-start-8">
              <div className="flex justify-between">
                <h1 className="text-xl font-medium text-gray-900">{product.name}</h1>
                <p className="text-xl font-medium text-gray-900">${selectedVariant?.price?.toString()}</p>
              </div>
              {/* Reviews */}
              <div className="hidden mt-4">
                <h2 className="sr-only">Reviews</h2>
                <div className="flex items-center">
                  <p className="text-sm text-gray-700">
                    {product.rating}
                    <span className="sr-only"> out of 5 stars</span>
                  </p>
                  <div className="ml-1 flex items-center">
                    {[0, 1, 2, 3, 4].map((rating) => (
                      <StarIcon
                        key={rating}
                        className={classNames(
                          product.rating > rating ? 'text-yellow-400' : 'text-gray-200',
                          'h-5 w-5 flex-shrink-0'
                        )}
                        aria-hidden="true"
                      />
                    ))}
                  </div>
                  <div aria-hidden="true" className="ml-4 text-sm text-gray-300">
                    ·
                  </div>
                  <div className="ml-4 flex">
                    <a href="#" className="text-sm font-medium text-indigo-600 hover:text-indigo-500">
                      See all {product.reviewCount} reviews
                    </a>
                  </div>
                </div>
              </div>
            </div>

            {/* Image gallery */}
            <div className="mt-8 lg:col-span-7 lg:col-start-1 lg:row-span-3 lg:row-start-1 lg:mt-0">
              <h2 className="sr-only">Images</h2>

              <ImageGallery
                items={galleryImages}
                startIndex={currentIndex}
              />

      </div>

            <div className="mt-8 lg:col-span-5">
              <form>
                {/* Color picker */}
                <div>
                  <h2 className="text-sm font-medium text-gray-900">Color</h2>

                  <RadioGroup value={selectedColor} onChange={setSelectedColor} className="mt-2">
                    <RadioGroup.Label className="sr-only">Choose a color</RadioGroup.Label>
                    <div className="flex items-center space-x-3">
{
  // product.variants &&
  // product.variants
  //   .reduce((uniqueColors, variant) => {
  //     if (!uniqueColors.find((color) => color.color === variant.color)) {
  //       uniqueColors.push(variant);
  //     }
  //     return uniqueColors;
  //   }, [])
  colors
    ?.map((color) => (
      <RadioGroup.Option
        key={color.value}
        value={color}
        className={({ active, checked }) =>
          classNames(
            // variant.in_stock
            //   ? "cursor-pointer focus:outline-none"
            //   : "cursor-not-allowed opacity-25",
            "cursor-pointer focus:outline-none",
            active ? "ring-2 ring-indigo-500 ring-offset-2" : "",
            checked
              ? "border-transparent bg-indigo-600 text-white hover:bg-indigo-700"
              : "border-gray-200 bg-white text-gray-900 hover:bg-gray-50",
            "relative -m-0.5 flex cursor-pointer items-center justify-center rounded-full p-0.5 focus:outline-none"
          )
        }
        title={color.title}
        // disabled={!variant.in_stock}
      >
        <RadioGroup.Label as="span" className="sr-only">
          {color.title}
        </RadioGroup.Label>
        <span
          aria-hidden="true"
          style={{backgroundColor: color.value}}
          className="h-8 w-8 rounded-full border border-black border-opacity-10"
        />
      </RadioGroup.Option>
    ))
}
                    </div>
                  </RadioGroup>
                </div>

                {/* Size picker */}
                <div className="mt-8">
                  <div className="hidden flex items-center justify-between">
                    <h2 className="text-sm font-medium text-gray-900">Size</h2>
                    <a href="#" className="text-sm font-medium text-indigo-600 hover:text-indigo-500">
                      See sizing chart
                    </a>
                  </div>

                  <RadioGroup value={selectedSize} onChange={setSelectedSize} className="mt-2">
                    <RadioGroup.Label className="sr-only">Choose a size</RadioGroup.Label>
                    <div className="grid grid-cols-3 gap-3 sm:grid-cols-6">
{
  // product.variants &&
  // product.variants
  //   .reduce((uniqueSizes, variant) => {
  //     if (!uniqueSizes.find((size) => size.size === variant.size)) {
  //       uniqueSizes.push(variant);
  //     }
  //     return uniqueSizes;
  //   }, [])
  sizes
    ?.map((size) => (
      <RadioGroup.Option
        key={size.value}
        value={size}
        className={({ active, checked }) =>
          classNames(
            // variant.in_stock
            //   ? "cursor-pointer focus:outline-none"
            //   : "cursor-not-allowed opacity-25",
            "cursor-pointer focus:outline-none",
            active ? "ring-2 ring-indigo-500 ring-offset-2" : "",
            checked
              ? "border-transparent bg-indigo-600 text-white hover:bg-indigo-700"
              : "border-gray-200 bg-white text-gray-900 hover:bg-gray-50",
            "flex items-center justify-center rounded-md border py-3 px-3 text-sm font-medium uppercase sm:flex-1"
          )
        }
        // disabled={!variant.in_stock}
      >
        <RadioGroup.Label as="span">{size.title}</RadioGroup.Label>
      </RadioGroup.Option>
    ))
}


                    </div>
                  </RadioGroup>
                </div>



              <Link to="https://product-engine.kitkoo.com/generator/products">
                <button
                  type="button"
                  className="mt-8 flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-8 py-3 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  onClick={handleAddToStore}
                >
                  Add to Store
                </button>
              </Link>

              </form>

              {/* Product details */}
              <div className="hidden mt-10">
                <h2 className="text-sm font-medium text-gray-900">Description</h2>

                <div
                  className="prose prose-sm mt-4 text-gray-500"
                  dangerouslySetInnerHTML={{ __html: product?.description }}
                />
              </div>

              <div className="mt-8 border-t border-gray-200 pt-8">
                <h2 className="text-sm font-medium text-gray-900">Fabric &amp; Care</h2>

                <div className="prose prose-sm mt-4 text-gray-500">
                  <ul role="list">
                    {productOld.details.map((item) => (
                      <li key={item}>{item}</li>
                    ))}
                  </ul>
                </div>
              </div>

              <div className="hidden mt-8 border-t border-gray-200 pt-8">
                <h2 className="text-sm font-medium text-gray-900">Supplier - {product?.provider}</h2>

                <div className="prose prose-sm mt-4 text-gray-500">

                </div>
              </div>

              {/* Policies */}
              <section aria-labelledby="policies-heading" className="mt-10">
                <h2 id="policies-heading" className="sr-only">
                  Our Policies
                </h2>

                <dl className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2">
                  {policies.map((policy) => (
                    <div key={policy.name} className="rounded-lg border border-gray-200 bg-gray-50 p-6 text-center">
                      <dt>
                        <policy.icon className="mx-auto h-6 w-6 flex-shrink-0 text-gray-400" aria-hidden="true" />
                        <span className="mt-4 text-sm font-medium text-gray-900">{policy.name}</span>
                      </dt>
                      <dd className="mt-1 text-sm text-gray-500">{policy.description}</dd>
                    </div>
                  ))}
                </dl>

                <div className="mt-8">
                  <h2 className="text-sm font-medium text-gray-900">Mockups Available</h2>
                  <BaseImageSlider
                    baseImages={getUniqueBaseImages()}
                    onBaseImageSelect={handleBaseImageSelect}
                    selectedBaseImageId={selectedBaseImageId}
                  />
                </div>

    <div id="accordion-collapse" data-accordion="collapse">
      <h2 id="accordion-collapse-heading-1">
        <button
          type="button"
          className="flex items-center justify-center w-full py-3 px-5 text-white bg-purple-500 rounded-lg shadow-md focus:outline-none hover:bg-purple-600 transition-colors duration-300 font-semibold text-xl"
          style={{fontFamily: 'Inter, sans-serif'}}
          onClick={() => setIsOpen(!isOpen)}
          aria-expanded={isOpen}
          aria-controls="accordion-collapse-body-1"
        >
        Product Variants
        <svg
          className={`w-6 h-6 ml-2 transform ${isOpen ? 'rotate-180' : ''}`}
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
        </svg>
        </button>

      </h2>
      {isOpen && (
  <div id="accordion-collapse-body-1" className="" aria-labelledby="accordion-collapse-heading-1">
    <div className="border border-b-0 border-gray-200">
      <section className="bg-gray-50 py-3 sm:py-5">
        <div className="px-2 mx-auto max-w-screen-2xl lg:px-12">
          <div className="relative overflow-hidden bg-white shadow-md sm:rounded-lg">
            <div className="overflow-x-auto">
              <table className="w-full text-sm text-left text-gray-500">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                  <tr>
                    <th scope="col" className="px-2 py-3">Id</th>
                    <th scope="col" className="px-2 py-3">Color</th>
                    <th scope="col" className="px-2 py-3">Size</th>
                    <th scope="col" className="px-2 py-3">Price</th>
                    {/*<th scope="col" className="px-2 py-3 hidden md:block">In Stock</th>*/}
                    <th scope="col" className="px-2 py-3">Image</th>
                    <th scope="col" className="px-2 py-3 hidden md:block">Base Images</th>
                  </tr>
                </thead>
                <tbody>
                  {product.variants.map((variant, index) => (
                    <>
                      <tr key={`${variant.id}-info`} className="border-b hover:bg-gray-100">
                        <td className="px-2 py-2 font-medium text-gray-900 whitespace-normal">{variant.id}</td>
                        <td className="px-2 py-2 font-medium text-gray-900 whitespace-normal">
                          {variant.attributes?.color?.value ? (
                            <span
                              aria-hidden="true"
                              style={{backgroundColor: variant.attributes.color.value}}
                              title={variant.attributes.color.title}
                              className="h-8 w-8 rounded-full inline-block border border-black border-opacity-10"
                            />
                          ) : null}
                        </td>
                        <td className="px-2 py-2 font-medium text-gray-900 whitespace-normal">
                          {variant.attributes.size.title}
                        </td>
                        <td className="px-2 py-2 font-medium text-gray-900 whitespace-normal">{variant.price}</td>
                        {/*<td className="px-2 py-2 font-medium text-gray-900 whitespace-normal hidden md:block">*/}
                        {/*  {variant.availability_regions*/}
                        {/*    ? Object.values(variant.availability_regions)*/}
                        {/*        .map((region) => region)*/}
                        {/*        .join(', ')*/}
                        {/*    : 'N/A'}*/}
                        {/*</td>*/}
                        <td className="px-4 py-2 font-medium text-gray-900 whitespace-normal">
                          {variant.url_preview ? (
                            <img
                              src={variant.url_preview}
                              alt={`${product.title} ${variant.attribute?.color?.title} ${variant.attribute?.size?.title}`}
                              style={{ maxWidth: '50px' }}
                            />
                          ) : null}
                        </td>
                        <td className="px-4 py-2 font-medium text-gray-900 whitespace-normal hidden md:block">
                          <button
                            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-2 py-1.5 mr-2 mb-2 focus:outline-none"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#collapse${index}`}
                            aria-expanded="false"
                            aria-controls={`collapse${index}`}
                          >
                            View Mockups
                          </button>
                        </td>
                      </tr>
                      <tr key={`${variant.id}-base-images`} className="border-b hover:bg-gray-100">
                        <td colSpan="8" className="px-4 py-2">
                          <div className="collapse" id={`collapse${index}`}>
                            <div className="d-flex flex-wrap">
                              {getBaseImagesForVariant(variant).map((baseImage) => (
                                <figure
                                  className="mr-2 mb-2"
                                  key={`${variant.id}-${baseImage.id}`}
                                  style={{
                                    border: selectedBaseImageId === baseImage.id ? '2px solid blue' : 'none',
                                  }}
                                >
                                  <img
                                    src={baseImage.url_preview_rendered ?? baseImage.url_preview ?? baseImage.url}
                                    alt={`${product.title} ${variant.attribute?.color?.title} ${variant.attribute?.size?.title}`}
                                    style={{ maxWidth: '50px', marginRight: '5px' }}
                                    onClick={() => handleBaseImageSelect(baseImage.id)}
                                  />
                                  <figcaption className="mt-1" style={{ fontSize: '0.7rem' }}>
                                    g{baseImage.id}
                                  </figcaption>
                                </figure>
                              ))}
                            </div>
                          </div>
                        </td>
                      </tr>
                    </>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
)}

    </div>


              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  )


}
export default ProductDetails;
