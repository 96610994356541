import React, { useEffect, useState } from 'react';
import config from "./config";

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const tenant = '5cb30446-70f4-44a0-bb79-f79f84a7830f';

async function getGeneratedImage(id, token, maxRetries = 6, interval = 10000) {
  console.log('Generating image...');
    const requestOptions = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    };
  
    let retries = 0;
    let response;
  
    while (retries < maxRetries) {
      response = await fetch(
        `${config.productGeneratorApi.baseUrl}/tenant/generated-images/${id}`,
        requestOptions
      );
  
      const data = await response.json();
  
      if (data.data.state === 'ready') {
        return data.data.uri;
      }
  
      retries++;
      await delay(interval);
    }
  
    throw new Error('Failed to get the image after maximum retries');
  }
  

  const GeneratedImage = ({ imageId, clearImage, token }) => {
  const [imageUrl, setImageUrl] = useState('');

  useEffect(() => {
    if (clearImage) {
      setImageUrl('');
    } else if (imageId) {
      (async () => {
        try {
          const url = await getGeneratedImage(imageId, token);
          setImageUrl(url);
        } catch (error) {
          console.error('Error:', error.message);
        }
      })();
    }
  }, [imageId, clearImage]);
  return (
    <div>
    {imageUrl ? (
      <img src={imageUrl} alt="Generated image" style={{ maxWidth: '400px' }} />
    ) : (
      <div>Loading generated image...</div>
    )}
  </div>
  );
};

export default GeneratedImage;
