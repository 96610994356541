import React, { useState, useEffect ,useRef, Fragment} from 'react';
import lodash from 'lodash';
import { fetchProducts } from './api';
import ProductSidebar from './ProductSidebar';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import CustomPagination from './CustomPagination';

import { Dialog, Disclosure, Popover, Tab, Transition } from '@headlessui/react'
import { Bars3Icon, MagnifyingGlassIcon, ShoppingBagIcon, XMarkIcon } from '@heroicons/react/24/outline'
// import { ChevronDownIcon, PlusIcon } from '@heroicons/react/20/solid'



const ProductCatalog = () => {
  const debounceTimeout = useRef(null);

  let { typeName } = useParams();

  const [filteredProducts, setFilteredProducts] = useState([]);
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalProducts, setTotalProducts] = useState(0);
  const [typeFilter, setTypeFilter] = useState(typeName ?? 'All');

  const [brandFilter, setBrandFilter] = useState('All');
  const [colorFilter, setColorFilter] = useState('All');
  const [sizeFilter, setSizeFilter] = useState('All');
  const [searchTerm, setSearchTerm] = useState('');
  const [actualSearchTerm, setActualSearchTerm] = useState('');
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [types, setTypes] = useState([]);
  const [products, setProducts] = useState([]);
  const [baseImageCount, setBaseImageCount] = useState('all');
  const productsPerPage = 10;
  const [selectedSupplier, setSelectedSupplier] = useState(null); // Add this
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false)

  const breadcrumbs = [{ id: 1, name: 'Men', href: '#' }]

  const loadProducts = async (page, searchTerm, typeFilter, fetch_all = false, baseImageCount,selectedSupplier) => {
    try {
      const combinedSearchTerm =
        (searchTerm ? searchTerm : "") +
        (typeFilter && typeFilter !== "All" ? ` ${typeFilter}` : "");
      console.log("typeFilter:", typeFilter);
      const typeValue = typeFilter === 'All' ? '' : typeFilter;
      console.log("typeValue:", typeValue);

      const response = await fetchProducts(
        page,
        searchTerm.trim(),
        searchTerm.trim() !== "",
        typeValue,
        baseImageCount,
        selectedSupplier
         // Use type value from mapping
      );
  
  
      console.log('response', response);
  
      let productsWithTypes = [];
      
      if (response.products ) {
        console.log("response.products:", response.products);
        productsWithTypes = response.products.map((product) => {
          return { ...product, type: product.type || '' };
        });
      } else {
        // Commented out the following line
        // console.error('Unexpected response from the API. Expected an array of products.');
      }
      console.log("productsWithTypes:", productsWithTypes);

      setProducts(productsWithTypes);

      setTotalProducts(response.count);
      console.log("response.count:", response.count);
      const uniqueTypes = [
        ...new Set(
          productsWithTypes
            .filter((product) => product.type)
            .map((product) => product.type)
        ),
      ];
      setTypes(uniqueTypes);
      setTotalProducts(response.count);
    } catch (error) {
      console.error('Failed to load products:', error);
    }
  };

  // When type name from URL changes, update the type filter
  useEffect(() => {
    setTypeFilter(typeName ?? 'All');
  }, [typeName]);

  useEffect(() => {
    loadProducts(currentPage, actualSearchTerm, typeFilter, false, baseImageCount,selectedSupplier);
  }, [currentPage, typeFilter, brandFilter, colorFilter, sizeFilter, actualSearchTerm, baseImageCount]);


  const handleTypeFilter = (selectedType) => {
    const typeValue = selectedType === 'All' ? '' : selectedType;
    setTypeFilter(typeValue);
    setCurrentPage(1);
  };
  const handleBaseImageCountChange = (event) => {
    // debugger
    setBaseImageCount(event.target.checked); // use checked instead of value
  };
  
  
  const handleSupplierChange = (selectedSupplier) => {
    // debugger
    setSelectedSupplier(selectedSupplier);
    setCurrentPage(1);
    loadProducts(currentPage, actualSearchTerm, typeFilter, false, baseImageCount,selectedSupplier);
  };
 
  
  const handleBrandFilter = (event) => {
    setBrandFilter(event.target.value);
    setCurrentPage(1);
  };

  const handleColorFilter = (event) => {
    setColorFilter(event.target.value);
    setCurrentPage(1);
  };

  const handleSizeFilter = (event) => {
    setSizeFilter(event.target.value);
    setCurrentPage(1);
  };

  const handleSearchTermChange = (event) => {
    setSearchTerm(event.target.value);
    debounceUpdateSearchTerm(event.target.value);
  };

  
  const debounceUpdateSearchTerm = (value) => {
    clearTimeout(debounceTimeout.current);
    debounceTimeout.current = setTimeout(() => {
      setActualSearchTerm(value);
    }, 1500);
  };


  const handleTypeFilterChange = (value) => {
    setTypeFilter(value || 'All');
    setCurrentPage(1);
  };

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  console.log('products', products);

  const tempFilteredProducts = products
  ? products
      .filter((product) => typeFilter === 'All' || product.type === typeFilter)
      .filter((product) => {
        if (brandFilter === 'All') return true;
        return product.brand === brandFilter;
      })
      .filter((product) => {
        if (colorFilter === 'All') return true;
        return product.colors.includes(colorFilter);
      })
      .filter((product) => {
        if (sizeFilter === 'All') return true;
        return product.sizes.includes(sizeFilter);
      })
      .filter((product) => {
        if (searchTerm === '') return true;
        const lowerCaseSearchTerm = searchTerm.toLowerCase();
        const lowerCaseTitle = product.name.toLowerCase();
        // const lowerCaseDescription = product.description.toLowerCase();
        const lowerCaseDescription = '';
        return (
          lowerCaseTitle.includes(lowerCaseSearchTerm) ||
          lowerCaseDescription.includes(lowerCaseSearchTerm)
        );
      })
  : [];

  console.log("tempFilteredProducts:", tempFilteredProducts);

const currentProducts = tempFilteredProducts
? tempFilteredProducts.slice(indexOfFirstProduct, indexOfLastProduct)
: [];

const pageNumbers = [];
if (tempFilteredProducts) {
  for (let i = 1; i <= Math.ceil(tempFilteredProducts.length / productsPerPage); i++) {
    pageNumbers.push(i);
  }
}

const totalPages = Math.ceil(totalProducts / 10);

const dhandlePaginationItemClick = (e, pageNumber) => {
  e.preventDefault();
  e.stopPropagation();
  e.nativeEvent.stopImmediatePropagation(); // Add this line
  setCurrentPage(pageNumber);
};

const handlePaginationItemClick = (event, pageNumber) => {
  event.preventDefault();
  setCurrentPage(pageNumber);
  // Then load your data based on the new page number
};
 



const renderProductCards = () => {
  return (
    <div className="grid w-full grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-10 lg:gap-x-8 xl:grid-cols-3">
      {tempFilteredProducts.map((product) => {
        const productType = product.type ? lodash.startCase(product.type.replace('-', ' ')) : null
        return (
          <Link to={`/products/${product.id}`} productId={product.id} key={product.id}
                className="group relative flex flex-col overflow-hidden rounded-lg border border-gray-200 bg-white">
            <div className="aspect-w-3 aspect-h-4 bg-gray-200 sm:aspect-none group-hover:opacity-75 sm:h-96">
              <img
                src={product.url_preview}
                alt={product.url_preview}
                className="h-full w-full object-cover object-center sm:h-full sm:w-full"
              />
            </div>
            <div className="flex flex-1 flex-col space-y-2 p-4">
              <h3 className="text-sm font-medium text-gray-900">
                <span aria-hidden="true" className="absolute inset-0"/>
                {product.name}
              </h3>
              {/*<h4 className="text-sm text-gray-500">{product.name}</h4>*/}
              <p className="text-sm text-gray-500">{productType}</p>
              {/* Assuming that product has properties 'options' and 'price' */}
              {/* <div className="flex flex-1 flex-col justify-end">
              <p className="text-sm italic text-gray-500">{product.options}</p>
              <p className="text-base font-medium text-gray-900">{product.price}</p> 
            </div>*/}
            </div>
          </Link>
        )
      })}
    </div>
  );
  
};

 


return (
  <div className="bg-white">

    <div>
      {/* Mobile filter dialog */}
      <Transition.Root show={mobileFiltersOpen} as={Fragment}>
        <Dialog as="div" className="relative z-40 lg:hidden" onClose={setMobileFiltersOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
             <Dialog.Panel className="relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto bg-white py-4 pb-6 shadow-xl">
              <div className="flex items-center justify-between px-4">
                <h2 className="text-lg font-medium text-gray-900">Filters</h2>
                <button
                  type="button"
                  className="-mr-2 flex h-10 w-10 items-center justify-center p-2 text-gray-400 hover:text-gray-500"
                  onClick={() => setMobileFiltersOpen(false)}
                >
                  <span className="sr-only">Close menu</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>

              {/* Filters */}
              <ProductSidebar
                sidebarCollapsed={sidebarCollapsed}
                setSidebarCollapsed={setSidebarCollapsed}
                searchTerm={searchTerm}
                handleSearchTermChange={handleSearchTermChange}
                types={types}
                handleTypeFilterChange={handleTypeFilterChange}
                handleTypeFilter={handleTypeFilter}
                handleBrandFilter={handleBrandFilter}
                handleColorFilter={handleColorFilter}
                handleSizeFilter={handleSizeFilter}
                onTypeSelected={handleTypeFilter}
                baseImageCount={baseImageCount}
                handleBaseImageCountChange={handleBaseImageCountChange}
                handleSupplierChange={handleSupplierChange}
                selectedSupplier={selectedSupplier}
                typeName={typeName}
              />

            </Dialog.Panel>

            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <div className="border-b border-gray-200 hidden">
        <nav aria-label="Breadcrumb" className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <ol role="list" className="flex items-center space-x-4 py-4">
            {breadcrumbs.map((breadcrumb) => (
              <li key={breadcrumb.id}>
                <div className="flex items-center">
                  <a href={breadcrumb.href} className="mr-4 text-sm font-medium text-gray-900">
                    {breadcrumb.name}
                  </a>
                  <svg viewBox="0 0 6 20" aria-hidden="true" className="h-5 w-auto text-gray-300">
                    <path d="M4.878 4.34H3.551L.27 16.532h1.327l3.281-12.19z" fill="currentColor" />
                  </svg>
                </div>
              </li>
            ))}
            <li className="text-sm">
              <a href="#" aria-current="page" className="font-medium text-gray-500 hover:text-gray-600">
                New Arrivals
              </a>
            </li>
          </ol>
        </nav>
      </div>

      <main className="mx-auto max-w-2xl px-4 lg:max-w-7xl lg:px-8 ">
        <div className="border-b border-gray-200 pb-10 pt-24 hidden">
          <h1 className="text-4xl font-bold tracking-tight text-gray-900">New Arrivals</h1>
          <p className="mt-4 text-base text-gray-500">
            Checkout out the latest release of Basic Tees, new and improved with four openings!
          </p>
        </div>

        <div className="pb-24 pt-12 lg:grid lg:grid-cols-3 lg:gap-x-8 xl:grid-cols-4">
          <aside>
            <h2 className="sr-only">Filters</h2>
            <ProductSidebar
              sidebarCollapsed={sidebarCollapsed}
              setSidebarCollapsed={setSidebarCollapsed}
              searchTerm={searchTerm}
              handleSearchTermChange={handleSearchTermChange}
              types={types}
              handleTypeFilterChange={handleTypeFilterChange}
              handleTypeFilter={handleTypeFilter}
              handleBrandFilter={handleBrandFilter}
              handleColorFilter={handleColorFilter}
              handleSizeFilter={handleSizeFilter}
              onTypeSelected={handleTypeFilter}
              baseImageCount={baseImageCount}
              handleBaseImageCountChange={handleBaseImageCountChange}
              handleSupplierChange = {handleSupplierChange}
              selectedSupplier={selectedSupplier} // Add this
              typeName={typeName}
            />
       

       
          </aside>

          <section aria-labelledby="product-heading" className="mt-6 lg:col-span-2 lg:mt-0 xl:col-span-3">
            <h2 id="product-heading" className="sr-only">
              Products
            </h2>

              {/* {products.map((product) => (
                <div
                  key={product.id}
                  className="group relative flex flex-col overflow-hidden rounded-lg border border-gray-200 bg-white"
                >
                  <div className="aspect-h-4 aspect-w-3 bg-gray-200 sm:aspect-none group-hover:opacity-75 sm:h-96">
                    <img
                      src={product.imageSrc}
                      alt={product.imageAlt}
                      className="h-full w-full object-cover object-center sm:h-full sm:w-full"
                    />
                  </div>
                  <div className="flex flex-1 flex-col space-y-2 p-4">
                    <h3 className="text-sm font-medium text-gray-900">
                      <a href={product.href}>
                        <span aria-hidden="true" className="absolute inset-0" />
                        {product.name}
                      </a>
                    </h3>
                    <p className="text-sm text-gray-500">{product.description}</p>
                    <div className="flex flex-1 flex-col justify-end">
                      <p className="text-sm italic text-gray-500">{product.options}</p>
                      <p className="text-base font-medium text-gray-900">{product.price}</p>
                    </div>
                  </div>
                </div>
              ))} */}
              {renderProductCards()}
            <div className="mt-10">
            <CustomPagination currentPage={currentPage} totalPages={totalPages} handlePaginationItemClick={handlePaginationItemClick} />
            </div>
          </section>
          
        </div>
        
      </main>

     
    </div>
  </div>
)
 

};

export default ProductCatalog;
