import axios from 'axios';
import config from './config';
import qs from 'qs';

export const fetchProducts = async (
  page = 1,
  searchTerm = '',
  searchOnly = false,
  categoryValue = '',
  baseImageCount = 'all',
  selectedSupplier = '',
) => {

   const link = config.catalogApi.baseUrl;

  try {
    // let url = `${link}/products?page=${page}`;
    let url = `${link}/products`;

    const params = {
      page: page,
      limit: 12,
      filters: {}
    }

    if (searchOnly || searchTerm) {
      // url += `&filters[search]=${encodeURIComponent(searchTerm)}`;
      params.filters['search'] = searchTerm;
    } 

    if (categoryValue) {
      // url += `&filters[type]=${encodeURIComponent(categoryValue)}`;
      params.filters['type'] = categoryValue;
    }

    if (selectedSupplier) {
      // url += `&filters[provider]=${encodeURIComponent(selectedSupplier)}`;
      params.filters['provider'] = selectedSupplier;
    }


    if (baseImageCount !== 'all') {
      debugger
      // url += `&filters[baseImagesCount]=${baseImageCount}`;
      params.filters['baseImagesCount'] = baseImageCount;
    }



    const paramsSerializer = {
      serialize: (params) => {
        return qs.stringify(params, { arrayFormat: 'brackets' });
      }
    }

    const response = await axios.get(url, {params, paramsSerializer});
    const data = response.data;
  
    console.log('fetchProducts', data);

    const productsArray = data.products.map((product) => ({
      id: product?.id ?? null,
      name: product?.name ?? null,
      type: product?.type ?? null,
      price: product?.price ?? null,
      price_currency: product?.price_currency ?? null,
      attributes: product?.attributes ?? null,
      url_preview: product?.url_preview ?? null,
      provider_id: product?.provider_id ?? null,
      provider_product_id: product?.provider_product_id ?? null,
      created_at: product?.created_at ?? null,
      updated_at: product?.updated_at ?? null,
      base_images_count: product?.base_images_count ?? null,

      // id: product.id,
      // title: product.title,
      // description: product.description,
      // type: product.type,
      // variant_count: product.variant_count,
      // brand: product.brand,
      // image: product.image,
      // avg_fulfillment_time: product.avg_fulfillment_time,
      // model: product.model,
      // techniques: product.techniques,
      // files: product.files,
      // options: product.options,
      // production_days_min: product.production_days_min,
      // production_days_max: product.production_days_max,
      // release_status: product.release_status,
      // ship_zones: product.ship_zones,
      // operational_status: product.operational_status,
      // display_images: product.display_images,
      // sizes: product.sizes,
      // size_chart_link: product.size_chart_link,
      // colors: product.colors,
    }));

    return {
      products: productsArray,
      count: data.count
    };
  } catch (error) {
    console.log(error);
  }
};

export const fetchProduct = async (productId) => {
  const response = await fetch(`${config.catalogApi.baseUrl}/products/${productId}`);
  return await response.json();
};

export const fetchProductTypes = async () => {
  const response = await fetch(`${config.catalogApi.baseUrl}/product-types`);
  return await response.json();
};
