import React, { useState, useEffect } from 'react';
import { Carousel, Button, InputGroup, FormControl } from 'react-bootstrap';
import GeneratedImage from './GeneratedImage';
import config from "./config";

const BaseImageSlider = ({ baseImages, onBaseImageSelect, selectedBaseImageId: propSelectedBaseImageId }) => {

    const [apiResponse, setApiResponse] = useState(null);
    const defaultBaseImageId = baseImages[0]?.id;
    const [selectedBaseImageId, setSelectedBaseImageId] = useState(propSelectedBaseImageId || defaultBaseImageId);
    const [generatedImageId, setGeneratedImageId] = useState(null);
  
    // const [tenant, setTenant] = useState('');
    const [token, setToken] = useState(null);
    const [clearImage, setClearImage] = useState(false);
  

    const [isApiCalling, setIsApiCalling] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    const [isInitialRender, setIsInitialRender] = useState(true);
    const [isCallApiOnUpdate, setIsCallApiOnUpdate] = useState(false);

    useEffect(() => {
      console.log("[propSelectedBaseImageId]: propSelectedBaseImageId: ", propSelectedBaseImageId);
      console.log("[propSelectedBaseImageId]: defaultBaseImageId: ", defaultBaseImageId);
      console.log("[propSelectedBaseImageId]: selectedBaseImageId: ", selectedBaseImageId);
      if (propSelectedBaseImageId) {
        setSelectedBaseImageId(propSelectedBaseImageId);
      } else if (isInitialRender) {
        setSelectedBaseImageId(defaultBaseImageId);
        setIsInitialRender(false);
      }
      console.log("selectedBaseImageId after change: ", selectedBaseImageId);
  }, [propSelectedBaseImageId]);
  
  useEffect(() => {
    console.log("[selectedBaseImageId]: selectedBaseImageId", {
      selectedBaseImageId,
      isInitialRender,
      isCallApiOnUpdate
    });
    if (!isInitialRender && isCallApiOnUpdate && selectedBaseImageId) {
      console.log("[selectedBaseImageId]:calling API with selectedBaseImageId: ", selectedBaseImageId);
      // setGeneratedImageId(selectedBaseImageId);
      callApi(selectedBaseImageId);
    }
}, [selectedBaseImageId]);


  const callApi = async (baseImageId) => {
    console.log("calling API with baseImageId: ", baseImageId);

    setClearImage(true);
    setIsApiCalling(true);
    setIsCallApiOnUpdate(true);

    const overlayImageUrl = 'https://storage.googleapis.com/retooluploadstuff/coca-cola.png';

    const response = await fetch(`${config.productGeneratorApi.baseUrl}/tenant/generator/images/using-base-images`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        base_image_uids: ["g"+baseImageId],
        overlay_image_url: overlayImageUrl,
      }),
    });

    const data = await response.json();
    setApiResponse(data);
    setGeneratedImageId(data.data[0].id);

    // Reset clearImage to false after the API call is completed
    setIsApiCalling(false);
    setClearImage(false);
  };

  const isButtonDisabled = !token || isApiCalling;

  // Determine the grid columns based on the number of images
  let gridCols;
  if (baseImages.length > 3) {
    gridCols = 'lg:grid-cols-3';
  } else if (baseImages.length > 1) {
    gridCols = 'lg:grid-cols-2';
  } else {
    gridCols = 'lg:grid-cols-1';
  }
  
  return (
    <div className="container-fluid mx-0">
     <div className={`mx-auto mt-6 max-w-2xl sm:px-6 lg:grid lg:max-w-7xl lg:grid-cols-3 lg:gap-x-8 lg:px-8`}>
        {baseImages.map((baseImage, index) => {
          let aspectRatio, visibility;
          if (index === 0) {
            aspectRatio = 'aspect-h-4 aspect-w-3';
            visibility = 'lg:block';
          } else if (index < 3) {
            aspectRatio = 'aspect-h-2 aspect-w-3';
            visibility = 'hidden lg:grid';
          } else {
            aspectRatio = 'aspect-h-5 aspect-w-4 lg:aspect-h-4 lg:aspect-w-3';
            visibility = '';
          }
          let isSelected = baseImage.id === propSelectedBaseImageId;
          return (
            <div 
            key={baseImage.id} 
            className={`${aspectRatio} overflow-hidden rounded-lg ${visibility} transition-all duration-500 ease-in-out ${isSelected ? 'ring-4 ring-blue-500' : ''}`}
            onClick={() => {
              onBaseImageSelect(baseImage.id);
              setSelectedBaseImageId(baseImage.id);
            }}
            onMouseEnter={(e) => e.currentTarget.classList.add('ring-2', 'ring-blue-300')}
            onMouseLeave={(e) => e.currentTarget.classList.remove('ring-2', 'ring-blue-300')}
          >
          
              <img
                className="h-full w-full object-cover object-center p-3"
                src={baseImage.url}
                alt={baseImage.name ?? `Base image ${index + 1}`}
                title={baseImage.name ?? `Base image ${index + 1}`}
              />
            </div>
          );
        })}
      </div>
      <div id="accordion-collapse" data-accordion="collapse">
      <h2 id="accordion-collapse-heading-1" className="text-center my-4">
        <button 
          type="button" 
          className="flex items-center justify-center w-full py-3 px-5 text-white bg-blue-500 rounded-lg shadow-md focus:outline-none hover:bg-blue-600 transition-colors duration-300 font-semibold text-xl" 
          style={{fontFamily: 'Inter, sans-serif'}}
          onClick={() => setIsOpen(!isOpen)} 
          aria-expanded={isOpen} 
          aria-controls="accordion-collapse-body-1"
        >
        Test Mockup
        <svg 
          className={`w-6 h-6 ml-2 transform ${isOpen ? 'rotate-180' : ''}`} 
          fill="currentColor" 
          viewBox="0 0 20 20" 
          xmlns="http://www.w3.org/2000/svg"
        >
          <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
        </svg>
        </button>
      </h2>
      {isOpen && (
        <div id="accordion-collapse-body-1" className="accordion-collapse collapse show" aria-labelledby="accordion-collapse-heading-1">
    <div className="mb-3 p-5 border border-b-0 border-gray-200 dark:border-gray-200">
        <div className="mt-3">
            <div className="w-100 mr-3">
                {/*<InputGroup className="mb-3">*/}
                {/*    <InputGroup.Text className="bg-gray-100 text-black">Tenant</InputGroup.Text>*/}
                {/*    <FormControl*/}
                {/*        autoComplete="on"*/}
                {/*        placeholder="Enter tenant"*/}
                {/*        value={tenant}*/}
                {/*        onChange={(e) => setTenant(e.target.value)}*/}
                {/*        name="tenant"*/}
                {/*        className="form-control"*/}
                {/*    />*/}
                {/*</InputGroup>*/}
                <InputGroup className="mb-3">
                    <InputGroup.Text className="bg-gray-100 text-black">Token</InputGroup.Text>
                    <FormControl
                        autoComplete="on"
                        placeholder="Enter token"
                        value={token ?? ''}
                        onChange={(e) => setToken(e.target.value)}
                        name="token"
                        className="form-control"
                    />
                </InputGroup>
                <pre className="mb-3 text-black">
                    {JSON.stringify(
                        {
                            base_image_uids: ["g"+selectedBaseImageId],
                            overlay_image_url: 'https://storage.googleapis.com/retooluploadstuff/coca-cola.png',
                        },
                        null,
                        2
                    )}
                </pre>
                <Button
                    className="px-4 py-2 bg-blue-500 text-white rounded"
                    onClick={() => callApi(selectedBaseImageId)}
                    disabled={isButtonDisabled} 
                >
                    Call API
                </Button>
            </div>
            {apiResponse && (
                <div className="w-100 mr-3">
                    <label className="form-label text-black">API Response:</label>
                    <pre className="text-black">{JSON.stringify(apiResponse, null, 2)}</pre>
                </div>
            )}
            {generatedImageId && (
                <div className="w-100 apiImage">
                    <label className="form-label text-black">Generated Image:</label>
                    <GeneratedImage imageId={generatedImageId} clearImage={clearImage} token={token} />
                </div>
            )}
        </div>
    </div>
</div>

      )}
    </div>
 

   
    </div>
  );
};

export default BaseImageSlider;
