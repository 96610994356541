const CustomPagination = ({ currentPage, totalPages, handlePaginationItemClick }) => {
    const minPageNumber = Math.max(2, currentPage - 2);
    const maxPageNumber = Math.min(totalPages - 1, currentPage + 2);
  
    let paginationItems = [];
  
    // First page
    paginationItems.push(
      <li key="first" role="button" onClick={(e) => handlePaginationItemClick(e, 1)}>
        <a className="px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
          {'<<'}
        </a>
      </li>
    );
  
    // Ellipsis for previous pages
    if (currentPage > 3) {
      paginationItems.push(
        <li key="ellipsis-prev" className="px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
          {'...'}
        </li>
      );
    }
  
    // Page numbers
    for (let number = minPageNumber; number <= maxPageNumber; number++) {
      paginationItems.push(
        <li key={number} role="button" onClick={(e) => handlePaginationItemClick(e, number)}>
          <a className={`px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white ${number === currentPage ? 'bg-blue-50 text-blue-600 border-blue-300' : ''}`}>
            {number}
          </a>
        </li>
      );
    }
  
    // Ellipsis for next pages
    if (currentPage < totalPages - 2) {
      paginationItems.push(
        <li key="ellipsis-next" className="px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
          {'...'}
        </li>
      );
    }
  
    // Last page
    paginationItems.push(
      <li key="last" role="button" onClick={(e) => handlePaginationItemClick(e, totalPages)}>
        <a className="px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
          {'>>'}
        </a>
      </li>
    );
  
    return (
      <ul className="flex items-center justify-center list-none">
        {paginationItems}
      </ul>
    );
  };
  
  export default CustomPagination;
  