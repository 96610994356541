import React, { useState, Fragment,useEffect } from 'react';
import PropTypes from 'prop-types';
import { Dialog, Disclosure, Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon, FunnelIcon, MinusIcon, PlusIcon,IconName, Squares2X2Icon } from '@heroicons/react/20/solid'
import SortMenu from './SidebarSortMenu';
import { Form, InputGroup } from 'react-bootstrap'; // Add this line

import FilterComponent from './SidebarFilterComponent';

import { XMarkIcon } from '@heroicons/react/24/outline'
import { fetchProductTypes } from "./api";

const sortOptions = [
  { name: 'Most Popular', href: '#', current: true },
  { name: 'Best Rating', href: '#', current: false },
  { name: 'Newest', href: '#', current: false },
  { name: 'Price: Low to High', href: '#', current: false },
  { name: 'Price: High to Low', href: '#', current: false },
]
const subTypes = [
  { name: 'All', href: '/' },
  { name: 'Cut & Sew', href: '/type/cut-sew' },
  { name: 'Phone Case', href: '/type/phone-case' },
  { name: 'T Shirts', href: '/type/t-shirt' },
]

const supplierFilters = [
  {
    id: 'supplier',
    name: 'Suppliers',
    options: [
      { value: 'Printful', label: 'Printful', checked: false },
      { value: 'Dreamship', label: 'Dreamship', checked: false },
    ],
  }
];
//cc


//add byteflow and tailwind make the side bar catagories be checkboxe   

const ProductSidebar = ({ searchTerm, handleSearchTermChange, sidebarCollapsed, handleBaseImageCountChange, baseImageCount, onTypeSelected,handleSupplierChange,selectedSupplier,typeName }) => {
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);
  const [productTypes, setProductTypes] = useState([]); // add this
  const [selectedType, setSelectedType] = useState(null); // add this
  const [typeFilters, setTypeFilters] = useState([]); // add this



  const toggleMobileFilters = () => {
    debugger
    console.log("Before toggle:", mobileFiltersOpen);
    setMobileFiltersOpen(prev => !prev);
    console.log("After toggle:", mobileFiltersOpen);
  }

  const loadProductTypes = async () => {
    try {
      const response = await fetchProductTypes();
      console.log('[loadProductTypes] response', response);

      setProductTypes(response.types);

      setTypeFilters([
        {
          id: 1,
          name: "Types",
          options: response.types.map((type, index) => {
            return {
              value: type.value,
              label: type.label,
              checked: false
            }
          }),
        }
      ]);

    } catch (error) {
      console.error('Failed to load product types:', error);
    }
  };


  //
  // const typeFilters = [
  //
  // ];
  //
  // const filters = [
  //
  //   ...typeFilters
  // ]

  const handleTypeChange = (selectedType) => {
    // debugger
    console.log("selectedType:", selectedType); // Add this line for debugging

    setSelectedType(selectedType); // update selected type
    onTypeSelected(selectedType);
  };

  useEffect(() => {
    if(productTypes.length === 0) loadProductTypes();
  });

  useEffect(() => {
    console.log(`mobileFiltersOpen is now: ${mobileFiltersOpen}`);
  }, [mobileFiltersOpen]);

  useEffect(() => {
    console.log(`[productTypes]`, productTypes);
  }, [productTypes]);




  return (
    
    <div className="bg-white">
           <button
              type="button"
              className="inline-flex items-center lg:hidden"
              onClick={() => setMobileFiltersOpen(true)}
            >
              <span className="text-sm font-medium text-gray-700">Filters</span>
              <PlusIcon className="ml-1 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
            </button>
      <div>
        {/* Mobile filter dialog */}
        <Transition.Root show={mobileFiltersOpen} as={Fragment}>
          <Dialog as="div" className="relative z-40 lg:hidden" onClose={setMobileFiltersOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="relative ml-auto px-4 flex h-full w-full max-w-xs flex-col overflow-y-auto bg-white py-4 pb-12 shadow-xl">
                  <div className="flex items-center justify-between px-4">
                    <h2 className="text-lg font-medium text-gray-900">Filters</h2>
                    <button
  type="button"
  className="-mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-white p-2 text-gray-400"
  onClick={() => setMobileFiltersOpen(false)}
>
  <span className="sr-only">Close menu</span>
  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
</button>

                  </div>

                  {/* Filters */}
                  <form className="mt-4 border-t border-gray-200">
                    <h3 className="sr-only">Types</h3>
                    <div className="px2">
       {/* Add the search box here */}
       <Form.Group>
                      <Form.Label>Search</Form.Label>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          placeholder="Search products..."
                          value={searchTerm}
                          onChange={handleSearchTermChange}
                          style={{ minWidth: '100%' }}
                        />
                      </InputGroup>
                      <Form.Check
            type="checkbox"
            id="baseImageCountCheckbox"
            label="Toggle Base Image Count"
            onChange={handleBaseImageCountChange}
          />
                    </Form.Group>

                    </div>
                    <ul role="list" className="px-2 py-3 font-medium text-gray-900">
                      {subTypes.map((type) => (
                        <li key={type.name}>
                          <a href={type.href} className="block px-2 py-3">
                            {type.name}
                          </a>
                        </li>
                      ))}
                    </ul>

                    {typeFilters.map((section) => (
                      <FilterComponent
                        key={section.id}
                        section={section}
                        selectedType={selectedType}
                        onChange={handleTypeChange}
                      />
                    ))}
                    {supplierFilters.map((section) => (
                      <FilterComponent
                        key={section.id}
                        section={section}
                        selectedType={selectedSupplier}
                        onChange={handleSupplierChange}
                      />
                    ))}
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        <main className={`${mobileFiltersOpen ? 'block' : 'hidden'} lg:block mx-auto max-w-7xl px-4 sm:px-6 lg:px-8`}>
          <div className="flex items-baseline justify-between border-b border-gray-200 pb-4 pt-10">
          <h1 className="text-2xl font-bold tracking-tight text-gray-900">{selectedType || typeName || "All Products"}</h1>

      

            <div className="flex hidden items-center">
            <SortMenu sortOptions={sortOptions} />


              <button type="button" className="-m-2 ml-5 p-2 text-gray-400 hover:text-gray-500 sm:ml-7">
                <span className="sr-only">View grid</span>
                <Squares2X2Icon className="h-5 w-5" aria-hidden="true" />
              </button>
              <button
                type="button"
                className="-m-2 ml-4 p-2 text-gray-400 hover:text-gray-500 sm:ml-6 lg:hidden"
                onClick={toggleMobileFilters}

              >
                <span className="sr-only">Filters</span>
                <FunnelIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
            
          </div>
          <div className="pb-1 pt-6">
       {/* Add the search box here */}
       <Form.Group>
                      <Form.Label>Search</Form.Label>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          placeholder="Search products..."
                          value={searchTerm}
                          onChange={handleSearchTermChange}
                          style={{ minWidth: '100%' }}
                        />
                      </InputGroup>      
                
                    </Form.Group>
                    <Form.Group className="pt-4 pb-6 border-b border-gray-200">
  <div className="flex items-center">
    <Form.Check
      type="checkbox"
      id="baseImageCountCheckbox"

      onChange={handleBaseImageCountChange}
      className="  h-6 w-6"
    />
    <label htmlFor="baseImageCountCheckbox" className="ml-2   text-gray-900">
      Only Products With Mockup
    </label>
  </div>
</Form.Group>


                    </div>
          <section aria-labelledby="products-heading" className="pb-24 pt-6">
            <h2 id="products-heading" className="sr-only">
              Products
            </h2>

            <div className="grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-1">
              {/* Filters */}
              <form className="lg:block">
                <h3 className="sr-only">Types</h3>
                <ul role="list" className="space-y-4 border-b border-gray-200 pb-6 text-sm font-medium text-gray-900">
                  {subTypes.map((type) => (
                    <li key={type.name}>
                      <a href={type.href}>{type.name}</a>
                    </li>
                  ))}
                </ul>
                {supplierFilters.map((section) => (
  <Disclosure as="div" key={section.id} className="border-b border-gray-200 py-6">
    {({ open }) => (
      <>
        <h3 className="-my-3 flow-root">
          <Disclosure.Button className="flex w-full items-center justify-between bg-white py-3 text-sm text-gray-400 hover:text-gray-500">
            <span className="font-medium text-gray-900">{section.name}</span>
            <span className="ml-6 flex items-center">
              {open ? (
                <MinusIcon className="h-5 w-5" aria-hidden="true" />
              ) : (
                <PlusIcon className="h-5 w-5" aria-hidden="true" />
              )}
            </span>
          </Disclosure.Button>
        </h3>
        <Disclosure.Panel className="pt-6">
          <div className="space-y-4">
            {section.options.map((option, optionIdx) => (
              <div key={option.value} className="flex items-center">
                <input
                  id={`filter-${section.id}-${optionIdx}`}
                  name={`${section.id}[]`}
                  value={option.value}
                  checked={selectedSupplier === option.value}
                  type="radio"
                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                  onChange={() => handleSupplierChange(option.value)}
                />
                <label
                  htmlFor={`filter-${section.id}-${optionIdx}`}
                  className="ml-3 text-sm text-gray-600"
                >
                  {option.label}
                </label>
              </div>
            ))}
          </div>
        </Disclosure.Panel>
      </>
    )}
  </Disclosure>
))}

{typeFilters.map((section) => (
  <Disclosure as="div" key={section.id} className="border-b border-gray-200 py-6">
    {({ open }) => (
      <>
        <h3 className="-my-3 flow-root">
          <Disclosure.Button className="flex w-full items-center justify-between bg-white py-3 text-sm text-gray-400 hover:text-gray-500">
            <span className="font-medium text-gray-900">{section.name}</span>
            <span className="ml-6 flex items-center">
              {open ? (
                <MinusIcon className="h-5 w-5" aria-hidden="true" />
              ) : (
                <PlusIcon className="h-5 w-5" aria-hidden="true" />
              )}
            </span>
          </Disclosure.Button>
        </h3>
        <Disclosure.Panel className="pt-6">
          <div className="space-y-4">
            {section.options.map((option, optionIdx) => (
              <div key={option.value} className="flex items-center">
                <input
                  id={`filter-${section.id}-${optionIdx}`}
                  name={`${section.id}[]`}
                  value={option.value}
                  checked={selectedType === option.value}
                  type="radio"
                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                  onChange={() => handleTypeChange(option.value)}
                />
                <label
                  htmlFor={`filter-${section.id}-${optionIdx}`}
                  className="ml-3 text-sm text-gray-600 capitalize lowercase-camel"
                  style={{ textTransform: 'capitalize' }}

                >
                  {option.label.toLowerCase().replace(/\b\w/g, c => c.toUpperCase())}
                </label>
              </div>
            ))}
          </div>
        </Disclosure.Panel>
        
      </>
    )}
  </Disclosure>
))}

              </form>

              {/* Product grid */}
              <div className="lg:col-span-3">{/* Your content */}</div>
            </div>
          </section>
        </main>
      </div>
    </div>
  )

};

ProductSidebar.propTypes = {
  searchTerm: PropTypes.string.isRequired,
  handleSearchTermChange: PropTypes.func.isRequired,
  sidebarCollapsed: PropTypes.bool.isRequired,
  onTypeSelected: PropTypes.func.isRequired,
  handleBaseImageCountChange: PropTypes.func.isRequired,
  baseImageCount: PropTypes.string.isRequired,
  handleSupplierChange: PropTypes.func.isRequired,
};

export default ProductSidebar;
//

 